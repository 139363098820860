@font-face {
  font-family: "Gellix";
  src: url("./assets/fonts/Gellix-Regular.otf") format("opentype"),
    url("./assets/fonts/Gellix-Regular.ttf") format("truetype"),
    url("./assets/fonts/Gellix-Regular.woff") format("woff"),
    url("./assets/fonts/Gellix-Regular.woff") format("woff2");
}

@font-face {
  font-family: "Gellix-Bold";
  src: url("./assets/fonts/Gellix-Bold.otf") format("opentype"),
    url("./assets/fonts/Gellix-Bold.ttf") format("truetype"),
    url("./assets/fonts/Gellix-Bold.woff") format("woff"),
    url("./assets/fonts/Gellix-Bold.woff2") format("woff2");
}

/* @font-face {
  font-family: "Gellix";
  src: local("Gellix-Bold") url("./assets/fonts/Gellix-Bold.otf")
    format("truetype");
} */

/* @font-face {
  font-family: "Gellix";
  src: local("Gellix"),
    url(./assets/fonts/Gellix-Regular.otf) format("truetype");
  url(../Gellix/Gellix/Gellix-Black.otf) format("truetype")
      url(../Gellix/Gellix/Gellix-BlackItalic.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-Bold.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-BoldItalic.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-ExtraBold.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-ExtraBoldItalic.otf) format("truetype")
      url(../Gellix/Gellix/Gellix-Light.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-LightItalic.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-Medium.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-MediumItalic.otf) format("truetype")
      url(../Gellix/Gellix/Gellix-RegularItalic.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-SemiBold.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-SemiBoldItalic.otf) format("truetype"),
    url(../Gellix/Gellix/Gellix-Thin.otf) format("truetype")
    url(../Gellix/Gellix/Gellix-ThinItalic.otf) format("truetype"); 
} */

/* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg'  */
